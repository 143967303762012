.sa-tags-container {
  .tags-header {
    padding: 4px 12px;
    background-color: #2d2d2d;
    border: 1px solid #2d2d2d;
    margin-bottom: 14px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: rgba(255, 255, 255, 0.7);
  }
  .tags-list {
    .tag-item {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 4px 12px;

      color: #d4d4d4;

      .tag-actions {
        display: none;
        .icon {
          &:before {
            color: #727272 !important;
          }
          &:hover {
            background-color: #d9d9d90d;
            &:before {
              color: #ffffff !important;
            }
          }
        }
      }
      &:hover {
        background-color: #2d2d2d;

        .tag-actions {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
