.event-list-search-container {
  width: 492px;
  height: 100%;

  .ui.input.focus > input,
  .ui.input > input:focus {
    border-color: lighten(#2d2d2d, 20%);
    box-shadow: none;
    background-color: lighten(#2d2d2d, 20%);

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
  }

  .search-box {
    margin: 7px 7px 7px 12px;
    border: 1px solid #232323;
    box-sizing: border-box;
    border-radius: 3px;

    input {
      height: 24px;
      background-color: #2d2d2d;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
    }

    i {
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover {
      background-color: lighten(#2d2d2d, 20%);

      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
