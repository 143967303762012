@import 'color';

html,
body {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

body {
}
