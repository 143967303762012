@import 'assets/styles/color.scss';

.action-rect {
  font-family: 'Avenir Next';

  .auth0-lock {
    .auth0-lock-center {
      vertical-align: unset;
      padding-top: 0 !important;

      form.auth0-lock-widget {
        .auth0-lock-content {
          padding-left: 0;
          padding-right: 0;

          .auth0-lock-form {
            .auth0-lock-tabs-container {
              // these are to decrease width of login/signup navigations
              margin-left: 0;
              margin-right: 0;
              margin-bottom: 60px;

              .auth0-lock-tabs {
                background: none;
                box-shadow: 0 1px 0 0 #d4d4d4;
                .auth0-lock-tabs-current {
                  box-shadow: 0 3px 0 0 #d4d4d4;
                }
              }

              a,
              span {
                color: rgba(212, 212, 212, 0.5);
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
              }

              span {
                // current tab color
                color: #d4d4d4;
              }
            }

            // Parent div cant be selected, no class!
            .auth0-lock-input-block {
              .auth0-lock-input-wrap {
                height: 40px;
                border: 1px solid #232323;
                border-radius: 4px;
                background-color: #2d2d2d;

                // These are to increase width of inputs
                margin-left: -20px;
                margin-right: -20px;

                .auth0-lock-input {
                  background-color: #2d2d2d;
                  color: #d4d4d4;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19px;

                  &::placeholder {
                    color: #737373;
                  }
                }
              }
            }

            .auth0-lock-alternative {
              margin: 10px -20px;
              margin-bottom: 0;
              text-align: left;

              .auth0-lock-alternative-link {
                color: #3794ff !important;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
              }
            }

            p > span {
              color: #d4d4d4;
            }
          }
        }

        .auth0-lock-terms {
          span a {
            color: #3794ff;
          }
        }

        // login/signup button style
        .auth0-lock-submit {
          height: unset;
          padding: 0;
          margin-top: 30px;

          background-color: #1d76e1 !important;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 600;

          // clear '>' after LOG IN label
          .auth0-label-submit {
            > span {
              display: none;
            }
          }
        }

        // signup terms style
        .auth0-lock-terms {
          background: none;
          padding: 0;
          text-align: left;
          color: #d4d4d4;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;

          a {
            color: #1158cf;
          }
        }

        // remove lock header
        .auth0-lock-cred-pane {
          background-color: transparent;
          .auth0-lock-header {
            display: none;
          }
        }
      }
    }
  }
}
