.broker-connection-container {
  position: absolute;
  bottom: -4px;
  right: -4px;

  &.connected {
    color: #2dcd8a;
  }

  &.disconnected {
    color: rgba(212, 212, 212, 0.4);
  }

  .icon {
    font-size: 10px !important;
  }
}
