.welcome-page-container {
  .welcome-page-header {
    height: 152px;
    padding: 48px 0 24px 48px;
  }

  .welcome-page-content {
    height: 100%;
    width: 100%;
    padding: 32px;
    & > div {
      max-width: 520px !important;
    }
  }

  .ui.button {
    //background-color: #1d73dd !important;
    padding: 6px 12px !important;
    height: 32px;

    &:hover {
      background: #2a84f1 !important;
    }
  }
}
