.credit-card-form {
  * {
    margin: unset !important;
  }

  & > .field,
  & > .fields {
    padding-left: 0.5em !important;
    margin-bottom: 24px !important;
  }

  .stripe-input {
    border-radius: 3px;
    padding: 10px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    background-color: #2d2d2d;
    border: 2px solid #474747;
    &:hover {
      background-color: #323232;
    }

    input {
      color: #d9d9d9 !important;
    }

    &.StripeElement--focus {
      background-color: #151515;
      color: inherit;
      border: 2px solid #2a84f1;
    }
  }
  label {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #9f9f9f !important;
    margin-bottom: 4px !important;
  }
}
