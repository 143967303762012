.apps-and-repositories-container {
  .apps-and-repositories-title {
    padding: 4px 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid #323232;
    color: rgba(255, 255, 255, 0.7);
  }

  .sidekick-accordion {
    height: calc(100% - 24px);
  }
}
