$purple: #2d40d0;
$purple-2: #4e42e3;
$purple-3: #4528ab;
$purple-3a: #451eae;
$purple-4: #351996;
$purple-5: #5a2adc;
$soft-purple: #a988ec;
$very-soft-purple: #766e7e;
$very-soft-purple2: #7b5bdd;

$ide-border-color: #404040;

$light-purple: #b7bdee;

$light-blue: #dadaef;
$light-blue-2: #e6e7ed;
$light-blue-3: #dce1ff;

$dirty-blue: #4d4f5f;
$dirty-blue-1: #3c5183;

$blue01: #2a23d7;
$blue02: #3556e8;
$blue03: #3162df;

$blackish: #1e1f27;
$blackish-2: #16171d;
$blackish-3: #222430;
$blackish-4: #6d6f7e;
$blackish-5: #5d5f68;
$blackish-6: #3d3d3d;
$blackish-7: #212121;

$master-white: #ffffff;
$white-1: #888888;
$white-2: #d0d0d0;
$white-3: #71737d;
$white-4: #f3f3f3;
$white-5: #e4e4e4;
$white-6: #eaeaea;

$master-grey: #f8f8f8;
$light-grey: #efefef;
$light-grey-2: #ececec;
$gray-1: #686868;
$gray-2: #6f6f6f;
$gray-3: #8d8d8d;
$gray-4: lightgray;
$gray-5: #969696;

$back_grey: #f7f7f7;
$red: #ea4b36;

$on: #76d668;
$off: #d66868;

$warning: #ec7d7d;
$information: #7d9cec;
$success: #80cb75;

$turquois: #33d7e3;
$green: #33c797;
$orange: #e2732f;
$yellow: #e2d994;

$link-color: #3794ff;
