.ui.modal.visible.onboarding-modal {
  padding: 24px;
  width: auto;
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.014em;
    color: #d9d9d9;
    padding: 0;
    margin-bottom: 16px;
  }

  .content {
    .modal-caption {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.014em;
      color: #cccccc;
      margin-bottom: 16px;
    }

    .onboarding-panel {
      display: flex;
      gap: 16px;
      height: 300px;
      max-height: 300px;
      .sidekick-accordion {
        height: calc(100% - 38px);
      }
      .video-list {
        width: 230px;
        min-width: 230px;
        .video-list-panel {
          height: 100%;
          overflow-y: scroll;
        }
      }
    }

    .video-container {
      position: relative;

      .loading {
        position: absolute;
        top: 60%;
        left: 240px;
        z-index: 0;
      }

      iframe {
        position: absolute;
      }
    }
    .onboarding-welcome {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    padding: unset;
  }
  .video-list-item {
    padding: 8px;
    width: 220px;
    border-radius: 3px;
    cursor: pointer;
    &.active {
      background-color: #151515;
      border: 1px solid #2a84f1;
    }
    div {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.014em;

      color: #d9d9d9;
    }
  }
}
