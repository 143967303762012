@import 'assets/styles/size.scss';

.workspace-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #232323;

  .button.disabled {
    background-color: #5f5f5f !important;
  }

  .column {
    padding: 0 !important;
  }

  label {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 4px;
  }

  .small-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 14px;
  }

  .back-to {
    font-size: 24px;
    cursor: pointer;
    color: #ababab;
    &:hover {
      color: #d9d9d9;
    }
  }

  .small-header-caption {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #d9d9d9;
  }

  .workspace-page-header-container {
    background-color: inherit;
    position: sticky;
    top: 0;
    z-index: 1000;
    .workspace-page-header-grid {
      max-width: 1000px !important;
      padding-top: 24px;
      margin-bottom: 0 !important;

      .workspace-page-header {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: #ebebeb;
        margin-bottom: 16px;
      }
      .menu-row {
        .ui.secondary.pointing.menu {
          border-bottom: unset;
          .item {
            margin-right: 32px;
          }
          .workspace {
            width: 100px;
          }
          .users,
          .billing {
            width: 60px;
          }
          .integrations {
            width: 110px;
          }
        }
        border-bottom: 3px solid #343636;
      }

      .header-caption {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }

      .menu-row {
        padding-bottom: unset;
      }
      .menu {
        width: fit-content;
        .item {
          width: 100px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          padding-left: 0;
          padding-right: 0;
          justify-content: center;
          color: #ababab;
          margin: auto;

          &.active {
            color: unset !important;
            border-bottom-color: #1d76e1 !important;
          }

          &:hover {
            color: #d9d9d9;
          }
        }
      }
    }
  }

  .workspace-page-main-grid {
    max-width: 1000px !important;
    background-color: inherit;
    margin-top: 24px !important;

    .users-tab-main-grid,
    .invoices-table-row {
      .invite-users-button {
        height: 24px;
      }
      .table-header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
