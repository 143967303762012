.sandbox-page-container {
  width: 100%;
  height: 100%;
  position: relative;

  .apps-and-repositories-container {
    .sidekick-accordion {
      pointer-events: none;
    }
  }

  .flexlayout__tab_button_trailing {
    pointer-events: none;
  }

  .sandbox-menu-container {
    position: absolute;
    right: 440px;
    top: 40px;
    z-index: 1000;
    .sandbox-menu,
    .adblock-warning-guide {
      padding: 12px 14px;
      width: 350px;
      background-color: #404040;
      box-shadow: 0 4px 8px -2px #070a0c3d;
    }

    .subtitle-s5 {
      //styleName: Subtitle/S5;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.005em;
      text-align: left;
    }

    .menu-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #d9d9d9;
      margin-bottom: 9px;
      i {
        transition: transform 0.3s linear;
        &.collapsed {
          transform: rotate(-90deg);
        }
        cursor: pointer;
        &:hover {
          background-color: #232323;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }
    }
    .sandbox-menu {
      margin-bottom: 10px;

      .step-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #d9d9d9;

        .open-app-button {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0;
          text-align: center;
          padding: 4px 8px;
          color: #cccccc;
        }
      }

      .menu-steps {
        background-color: #151515;
        border-radius: 4px;
        margin-top: 15px;
        transition: height 3s linear;

        .step {
          display: flex;
          gap: 10px;
          padding: 8px;
          cursor: pointer;
          i {
            font-size: 16px;
            &.check {
              color: #2a84f1;
            }
          }
          &:hover {
            background-color: #2d2d2d;
          }
          &:first-of-type {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          &:last-of-type {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }

      .menu-caption {
        border-top: 1px solid #323232;
        padding-top: 8px;
        margin-top: 15px;
      }

      .get-started-button {
        width: 100%;
        margin-top: 10px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
