@import 'assets/styles/color.scss';
@import 'assets/styles/size.scss';

.bellhop-ring-popup {
  box-shadow: 0 0 0 1px #e6db74 inset !important;
}

.custom-profile-menu-wrapper {
  position: fixed;
  width: 240px;
  bottom: 10px;
  left: -5px;
  background-color: #232323 !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4) !important;
  padding: 20px 0;
  color: #d9d9d9;

  .logout-section {
    border-top: 1px solid rgba(217, 217, 217, 0.25);
    padding-top: 10px !important;
    margin-bottom: -2px !important;
  }

  .menu-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding-left: 12px;
  }

  .menu-section-title {
    padding: 8px 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: #797979;
  }

  .menu-item-button {
    padding: 6px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &.new {
      display: flex;
      align-items: center;
      gap: 8px;

      .new-label {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #151515;
        background-color: #2cc356;
        padding: 0 4px 0 4px;
        border-radius: 8px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff;
    }
    &:last-of-type {
      margin-bottom: -12px !important;
    }
  }
}

.ui.popup.menu-popup {
  background: unset !important;
  margin: 0;
  box-shadow: unset !important;
}

.push-to-bottom {
  margin-top: auto;
}

.ui.segment.pushable .side-bar .item.menu-item {
  border-radius: 4px !important;

  &.settings-menu,
  &.push-to-bottom {
    //border: 1px solid rgba(255, 255, 255, 0.12) !important;
    &:hover {
      background-color: #575757 !important;
      border-radius: 4px !important;
    }

    &.active {
      background-color: #6e6e6e !important;
      border-radius: 4px !important;
      color: #ffffff;
    }
  }
  &#sidekick-logo {
    &:hover {
      background-color: unset !important;
    }
  }

  &#settings-user {
    //border: 1px solid #404040 !important;
    background-color: #b3b3b3;
    color: #404040;
    border-radius: 50% !important;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    &.active {
      outline: 4px solid #2d2d2d;
      color: #404040 !important;
    }
  }
}
