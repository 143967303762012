.source-account-list-wrapper {
  height: calc(100% - 24px); // 24px is for sources title height
  padding-top: 4px;
  color: #d4d4d4;
  overflow: auto;
  position: relative;
  .local-environment {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    gap: 0.7rem;
    margin-top: 60px;

    .title {
      color: #d4d4d4;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
    }

    .small-text {
      text-align: center;
      color: rgba(212, 212, 212, 0.76);
      font-size: 11px;
    }
  }
}

.source-provider-repos-wrapper {
  margin-bottom: 12px;
}

.source-account-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;

  .source-account-name {
    font-size: 12px;
  }

  .source-provider-options {
    i.icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.no-repository-wrapper {
  padding: 4px 16px;

  .no-repo-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;

    .title {
      color: #d4d4d4;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .text {
      text-align: center;
      color: rgba(212, 212, 212, 0.76);
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 4px;
    }

    a {
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.repo-list-wrapper {
  padding: 0 8px;

  .repo-list-item {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
  }

  .repo-list-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .repo-list-item-actions {
    display: flex;
    padding-left: 8px;

    i:hover {
      cursor: pointer;
    }
  }
}

.ui.modal.disconnect-source-repo-modal {
  width: 400px;
  padding: 16px;

  .disconnect-source-content {
    .disconnect-source-title {
      margin-bottom: 16px;

      i {
        margin-right: 10px;
      }
    }

    .disconnect-source-title span {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    .disconnect-source-text span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.ui.modal.add-source-repo-modal {
  width: 600px;
  padding: 16px;

  .repo-limit-content {
    .add-repo-title {
      margin-bottom: 16px;

      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }

      i {
        margin-right: 14px;
      }
    }

    .text a,
    .text span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .source-select-content {
    .add-repo-title {
      margin-bottom: 16px;

      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }
    }

    .change-branch-modal-warning {
      padding: 12px 16px;
      background-color: #232323;
      border: 1px solid #2d2d2d;
      // box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 16px;

      .title span {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
      }

      .title > span {
        margin-right: 14px;
      }

      .text span {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        margin-left: 29px;
      }
    }

    .source-repo-selection,
    .source-branch-selection {
      margin-bottom: 12px;
    }

    // .source-provider-connection,
    .source-repo-selection,
    .source-branch-selection,
    .source-commit-selection {
      .section-title {
        margin-bottom: 4px;

        span {
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
