.ui.modal.add-update-source-repo-modal {
  width: 600px;
  padding: 16px;

  .source-select-content {
    .add-repo-title {
      margin-bottom: 16px;

      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }
    }

    .change-branch-modal-warning {
      padding: 12px 16px;
      background-color: #232323;
      border: 1px solid #2d2d2d;
      // box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 16px;

      .title span {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
      }

      .title > span {
        margin-right: 14px;
      }

      .text span {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        margin-left: 29px;
      }
    }

    .source-repo-selection,
    .source-branch-selection {
      margin-bottom: 12px;
    }

    // .source-provider-connection,
    .source-repo-selection,
    .source-branch-selection,
    .source-commit-selection {
      .section-title {
        margin-bottom: 4px;

        span {
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
