.repository-management-wrapper {
  height: 100%;
  position: relative;
}

.repository-management-loading-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #32323286;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.7);

  .icon-thundra-loading {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.apps-and-repositories-container {
  .sources-title-wrapper {
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: space-between;
    height: 23px;
    border-bottom: 1px solid #323232;
    padding: 4px 8px 3px 8px;

    .title > span {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 16px;
    }

    .action-icons {
      display: flex;
      align-items: center;

      i {
        font-size: 12px;
        color: rgba(217, 217, 217, 0.5);

        &:hover {
          color: rgba(217, 217, 217, 1);
        }
      }

      .icon-misc-edit {
        margin-left: 8px;
      }

      i:hover {
        cursor: pointer;
      }
    }
  }

  .sources-wrapper {
    width: 100%;
    height: calc(100% - 35px);

    .sources-branch-commit-info {
      width: 100%;
      padding: 8px;

      span {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .no-source-wrapper {
    padding: 4px 16px;

    .arrow-pointer {
      position: absolute;
      top: 90px;
      left: 3px;
      transform: rotate(-45deg);
    }

    .folder-icon {
      text-align: center;
      margin: 12px 0;

      i {
        font-size: 40px;
      }

      .icon-apps-and-tps-select-applications:before {
        color: rgba(212, 212, 212, 0.4);
      }
    }

    .no-source-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: #d4d4d4;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 4px;
        background-color: unset;
      }

      .text {
        text-align: center;
        color: rgba(212, 212, 212, 0.76);
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 4px;
      }

      a {
        text-align: center;
      }
    }
  }

  .ui.popup.source-select-popup {
    width: 224px;

    .title {
      font-weight: bold;
      font-size: 9px;
      line-height: 16px;
      padding: 4px 0;
    }

    .item {
      padding: 4px 0;
    }

    .ready-sources {
      .item {
        cursor: pointer;
      }
    }

    .coming-soon-sources {
      margin-top: 8px;

      .item {
        color: #737373;
        display: flex;
        justify-content: space-between;

        .notify-button {
          cursor: pointer;

          span {
            color: #d3d3d3;
          }
        }
      }
    }
  }

  .number-circle {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 2px;
    background: #c4c4c4;
    color: #000;
    text-align: center;
    font: 10px Arial, sans-serif;

    &.check-mark {
      background-color: #2dcd8a;
    }
  }

  .ui.modal.source-select-modal {
    width: 600px;

    .source-select-content {
      .source-provider-connection,
      .source-repo-selection,
      .source-branch-selection {
        .section-title {
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          display: flex;

          .title {
            padding-left: 16px;
          }
        }

        .section-content {
          margin: 8px 8px;
          padding: 18px 24px;
          border-left: 1px solid #c4c4c4;

          .section-image {
            text-align: center;
          }

          .section-text {
            margin-top: 8px;
          }
        }
      }

      .source-branch-selection {
        .section-content {
          border-left: none;
        }
      }
    }
  }

  .search-title-section {
    display: flex;
    justify-content: space-between;

    .title {
      padding-left: 8px;
      padding-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: rgba(255, 255, 255, 0.7);
    }

    .icon-thundra-close-button {
      cursor: pointer;
      padding-right: 20px;
      padding-top: 6px;
      color: rgba(217, 217, 217, 0.5);
    }
  }
}
