.ui.modal.history-modal {
  width: 1200px;

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;

    color: #ebebeb;
  }

  .button-group {
    .selection-button {
      margin: unset;
      border-radius: unset;
      background-color: #2d2d2d !important;

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }

      &.selected {
        background-color: #1d73dd !important;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .section-header {
    width: 100%;
    padding: 24px 12px 0 24px;

    .button-group {
      margin-bottom: 16px;
    }
  }

  .event-history-page-graph-container {
    width: calc(100% - 48px);
    height: 400px;
    margin: 24px;

    background-color: #404040;
  }
}
.event-history-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #232323;

  .event-history-page-header-container {
    padding: 24px;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #404040;
    justify-content: space-between;

    .header {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;

      color: #ebebeb;
    }
  }

  .event-history-page-graph-container {
    width: calc(100% - 48px);
    height: 40%;
    margin: 24px;

    display: flex;
    align-items: center;
    background-color: #404040;
  }

  .event-history-page-events-container {
    width: calc(100% - 48px);
    height: 43%;
    margin-left: 24px;
    margin-right: 24px;
    background-color: #404040;
    display: flex;
    flex-direction: column;

    .events-table {
      display: flex;
      flex-grow: 1;
      overflow-y: auto;
      margin-left: 12px;

      .table-container {
        flex-grow: 1;
        flex-shrink: 1;
      }

      .event-details {
        width: 40%;
      }
    }
  }

  .button-group {
    .selection-button {
      margin: unset;
      border-radius: unset;
      background-color: #2d2d2d !important;

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }

      &.selected {
        background-color: #1d73dd !important;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .section-header {
    width: 100%;
    padding: 24px 12px 0 24px;

    .button-group {
      margin-bottom: 16px;
    }
  }

  .sub-header {
    display: flex;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: #d9d9d9;
    gap: 20px;
    align-items: center;

    & > div > .event-list-search-container {
      width: 300px;
    }
  }

}


.recharts-default-tooltip {
  background-color: #232323 !important;
  border: 1px solid #404040 !important;
}


.ui.fluid.input > input::placeholder {
  color: #d9d9d9;
}