.no-resource-available-container {
  display: block;
  height: 100%;
  padding-top: 50px;

  .image-and-text-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;

    background-image: url('~assets/images/no-result-ill@2x.png');
    background-size: 44px 50px;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding-top: 130px;

    &:after {
      content: 'No Result';
      height: 15px;
      width: 55px;
      color: #6b7488;
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
    }
  }
}

// L O A D I N G  C H A R T
// Load lines come here

//table loading
.thu-custom-table {
  .ReactTable {
    .rt-table {
      .rt-tbody {
        min-height: 300px;
      }
    }
    .-loading {
      background: #f8f8f8;
      border-radius: 7px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      margin-left: -1px;
      margin-top: -1px;

      .table-loading-box {
        width: 100%;
        min-height: 300px;
        position: absolute;
        top: -120px;
        left: 0;
      }
    }
  }
}
