.email-error-popup {
  z-index: 3000 !important;
}

.invite-users-container {
  .email-rows {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .add-user {
      width: fit-content;
    }
  }
}

.add-user {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #3794ff;
}

.email-container {
  width: 95% !important;
}

.new-email-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.erroneous-email {
  input {
    border: 2px solid crimson !important;
  }
}
