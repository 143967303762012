.ui.vertical.menu {
  .workspace-switcher {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .item.workspace-item {
    display: flex;
    padding: unset !important;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    margin: auto;

    font-size: 11px;
    line-height: 13px;
    text-align: center;
    font-weight: 600;

    background-color: #2d2d2d;
    border-radius: 5px !important;
    color: rgba(255, 255, 255, 0.64);
    &:hover {
      background-color: #1b1c1d;
      outline: 3px solid #ffffff1f;
    }

    &.active {
      color: #ffffff;
      outline: 3px solid #9c9c9c;
    }
  }
}
