@font-face {
  font-family: 'sidekickfonts';
  src:  url('fonts/sidekickfonts.eot?kuw4t5');
  src:  url('fonts/sidekickfonts.eot?kuw4t5#iefix') format('embedded-opentype'),
    url('fonts/sidekickfonts.ttf?kuw4t5') format('truetype'),
    url('fonts/sidekickfonts.woff?kuw4t5') format('woff'),
    url('fonts/sidekickfonts.svg?kuw4t5#sidekickfonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sidekickfonts' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bug-left-nav-icon:before {
  content: "\e925";
  color: #fff;
}
.icon-bug-middle-big:before {
  content: "\e926";
}
.icon-bug-right-tab-icon:before {
  content: "\e927";
  color: #fff;
}
.icon-apps-and-tps-close-light:before {
  content: "\e900";
  color: #d9d9d9;
}
.icon-apps-and-tps-close:before {
  content: "\e901";
  color: #d9d9d9;
}
.icon-apps-and-tps-disable-tp:before {
  content: "\e902";
  color: #d9d9d9;
}
.icon-apps-and-tps-enable-tp:before {
  content: "\e903";
  color: #d9d9d9;
}
.icon-apps-and-tps-remove-tp:before {
  content: "\e904";
  color: #d9d9d9;
}
.icon-apps-and-tps-select-applications:before {
  content: "\e905";
  color: #d4d4d4;
}
.icon-apps-and-tps-set-trace-points:before {
  content: "\e906";
  color: #d4d4d4;
}
.icon-apps-and-tps-source-focus:before {
  content: "\e907";
  color: #d9d9d9;
}
.icon-apps-and-tps-waiting-tp:before {
  content: "\e908";
  color: #fff;
}
.icon-folder_icon:before {
  content: "\e909";
  color: #d9d9d9;
}
.icon-gutter-tracepoint-disable:before {
  content: "\e90a";
  color: #8d80dc;
}
.icon-gutter-tracepoint-disconnected:before {
  content: "\e90b";
  color: #8c8c8c;
}
.icon-gutter-tracepoint-enable:before {
  content: "\e90c";
  color: #8d80dc;
}
.icon-gutter-tracepoint-waiting:before {
  content: "\e90d";
  color: #8d80dc;
}
.icon-misc-edit:before {
  content: "\e90e";
  color: #d9d9d9;
}
.icon-sidebar-bird:before {
  content: "\e90f";
  color: #fff;
}
.icon-sidebar-bug-connect:before {
  content: "\e910";
  color: #2dcd8a;
}
.icon-sidebar-bug-disconnect:before {
  content: "\e911";
  color: #d4d4d4;
}
.icon-sidebar-bug:before {
  content: "\e912";
  color: #fff;
}
.icon-sidebar-intercom:before {
  content: "\e913";
  color: #d9d9d9;
}
.icon-source-control-add-source:before {
  content: "\e914";
  color: #d9d9d9;
}
.icon-source-control-refresh-source:before {
  content: "\e915";
  color: #d9d9d9;
}
.icon-source-control-search-source:before {
  content: "\e916";
  color: #d9d9d9;
}
.icon-source-control-sourceforge:before {
  content: "\e917";
  color: #737373;
}
.icon-thundra-clear-button:before {
  content: "\e918";
  color: #d9d9d9;
}
.icon-thundra-close-button:before {
  content: "\e919";
  color: #d9d9d9;
}
.icon-thundra-loading:before {
  content: "\e91a";
  color: #fff;
}
.icon-thundra-search-button:before {
  content: "\e91b";
  color: #d9d9d9;
}
.icon-tp-events-external:before {
  content: "\e91c";
  color: #d9d9d9;
}
.icon-tp-events-filter:before {
  content: "\e91d";
  color: #d9d9d9;
}
.icon-tp-events-no-event-exist:before {
  content: "\e91e";
  color: #d4d4d4;
}
.icon-tp-events-trash:before {
  content: "\e91f";
  color: #d9d9d9;
}
.icon-variable-tree-field2:before {
  content: "\e920";
  color: #6897bb;
}
.icon-variables-tree-chevron-down:before {
  content: "\e921";
  color: #d9d9d9;
}
.icon-variables-tree-chevron-up:before {
  content: "\e922";
  color: #d9d9d9;
}
.icon-variables-tree-field:before {
  content: "\e923";
  color: #cc7832;
}
.icon-variables-tree-parent:before {
  content: "\e924";
  color: #cc7832;
}
