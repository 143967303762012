.integrations-tab-root {
  .integrations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .new-integration {
      height: 24px;
    }
  }

  .settings-table {
    .actions-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .default-icons-container {
        display: flex;
        width: 120px;
        justify-content: center;
        align-items: center;

        .default-wh-icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;

          &.hidden {
            visibility: hidden;
          }
        }
        .tp-icon {
          background-color: #8d80dc;
          margin-right: auto;
        }
        .lp-icon {
          background-color: #ffd233;
          margin-left: auto;
        }

        .es-icon {
          background-color: crimson;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.ui.modal.visible.integration-modal {
  display: flex !important;
  flex-direction: column;
  width: 820px;
  height: 580px;
  padding: 24px 24px 0;

  .header {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #d9d9d9;
  }
  .content {
    flex-grow: 1;
    padding: 0;

    .modal-caption {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.014em;
      color: #cccccc;
      margin: 16px 0;
    }

    .integration-panel {
      display: flex;
      gap: 16px;
      .integrations-list {
        width: 240px;
        min-width: 240px;
        .integration-item {
          width: 100%;
          height: 36px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.014em;
          margin-bottom: 4px;
          padding: 8px 12px;
          display: flex;
          align-content: center;
          cursor: pointer;

          &:hover {
            background: #232323;
            box-shadow: 0 4px 8px -2px rgba(7, 10, 12, 0.24), 0px 0px 1px rgba(7, 10, 12, 0.48);
            border-radius: 3px;
          }

          &.active {
            background-color: #151515;
            border: 1px solid #2a84f1;
            border-radius: 3px;
          }
        }
      }
      .integration-settings {
        background-color: #151515;
        border-radius: 3px;
        box-shadow: 0 4px 8px -2px rgba(7, 10, 12, 0.24), 0px 0px 1px rgba(7, 10, 12, 0.48);
        flex-grow: 1;
        padding: 24px 16px;
        max-height: 372px;
        height: 372px;
        overflow-y: scroll;
        .field.erroneous {
          .ui.input {
            input {
              border-color: crimson;
            }
          }
        }
      }
    }
  }
  .actions {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
  }
}

.integrations-menu {
  background-color: #151515 !important;
  width: 210px;

  .switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #d9d9d9;
    }
  }
  .react-switch-bg {
    border: 1px solid #404040 !important;
  }
  .react-switch-handle {
    box-shadow: unset !important;
    border: 1px solid #404040 !important;
  }
}
