.flexlayout__tab_border_left,
.flexlayout__tab_border_right,
.flexlayout__tab_border_bottom {
  // border: 3px solid transparent;
  -webkit-transition: border 0.1s linear, box-shadow 0.1s linear;
  -moz-transition: border 0.1s linear, box-shadow 0.1s linear;
  transition: border 0.1s linear, box-shadow 0.1s linear;

  &.glow {
    //border-color: #2A84F1;
    border: 3px solid #2a84f1;
    -webkit-box-shadow: 0 0 5px blue;
    -moz-box-shadow: 0 0 5px blue;
    box-shadow: 0 0 5px blue;
  }
}

.ide-tab-button-icon {
  vertical-align: middle;
  margin: 0 5px 0 0;

  &.left-side {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);

    &.sources {
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;

      content: url('~assets/ide-layout-styles/images/IDE_TabButton_Sources.svg');
    }
  }

  &.right-side {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);

    &.app-and-tracepoint {
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;

      content: url('~assets/ide-layout-styles/images/IDE_TabButton_AppsTracepoints.svg');
    }
  }

  &.top-side {
    &.home {
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;
      content: url('~assets/ide-layout-styles/images/IDE_TabButton_Home.svg');
    }
  }
}

.ide-complex-layout-container {
  display: flex;
  width: 100%;
  height: 100%;

  .apps-and-repos-layout {
    margin-right: 4px;
    &.glow {
      //border-color: #2A84F1;
      border: 3px solid #2a84f1;
      -webkit-box-shadow: 0 0 5px blue;
      -moz-box-shadow: 0 0 5px blue;
      box-shadow: 0 0 5px blue;
    }
  }

  .flexlayout__layout {
    position: relative;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  }

  .btn-drag2 {
    width: 4px;
    border: none;
    padding: unset;
    background-color: #1c1c19;
    cursor: ew-resize;
    position: fixed;
    height: 100%;
    z-index: 1000;
  }
}
