.thundra-source-welcome-container {
  width: 100%;
  min-height: 100%;
  background-color: #232323;
  font-family: Inter;

  .container {
    margin: auto;
    width: 480px;
    min-height: 100%;
    padding-top: 48px;

    .bird {
      display: flex;
      border-bottom: 12px;
      height: 52px;
      margin-bottom: 12px;
    }

    .section {
      margin-bottom: 24px;
    }

    .section:last-child {
      margin-bottom: 0;
      padding-bottom: 24px;
    }

    .list {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: rgba(212, 212, 212, 0.76);
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      align-items: center;
      color: #8a8a8a;
    }

    .subtitle {
      display: flex;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #d4d4d4;
    }

    a {
      cursor: pointer;
    }

    .ide-integrations {
      display: flex;
      gap: 16px;
      .ide-item {
        padding: 12px 8px;
        background-color: #151515;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #4d4d4d;
        }

        .ide {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.005em;
        }
      }
    }

    .highlight-box {
      display: flex;
      align-items: center;
      background-color: #151515;
      border-radius: 3px;
      padding: 4px 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 4px;

      .left-item {
        padding: 8px 0;
        float: left;
      }

      .clickable {
        cursor: pointer;
      }

      .highlight-box-content {
        flex-grow: 1;
        padding: 8px;
      }

      .highlight-box-title {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #ffffff;
      }

      &.disabled {
        background: rgba(21, 21, 21, 0.4);
        color: rgba(115, 115, 115, 0.76);
        .highlight-box-title {
          color: #737373;
        }
      }
    }
  }
}
