.form-action-row-user-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-top: 24px;
}

.invite-users-action-row {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.column {
  padding: 0 !important;
}

.form-header-row {
  padding-bottom: 20px !important;
  .form-header {
    padding-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .form-header-caption {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.form-action-row-workspace-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;

  .next-step {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
