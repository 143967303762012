.app-management-container {
  padding-top: 16px;

  .event-list-search-container {
    width: unset;
    .search-box {
      margin: unset;
    }
  }

  .app-item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .apps-and-tps-placeholder {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i:before {
      color: rgba(212, 212, 212, 0.4);
    }

    .content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      color: #d4d4d4;
    }

    .description {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.app-item {
  display: flex;
  flex-direction: column;

  gap: 9px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #d4d4d4;

  &:hover {
    background-color: #4d4d4d;
  }
  .app-header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: -8px;
    padding-left: 8px;
    padding-bottom: 2px;
    padding-top: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;

    &:hover {
      background-color: #2d2d2d;
    }

    .actions {
      margin-left: auto;
      margin-right: 4px;
      display: flex;
      align-items: center;
      .more-settings {
        width: 16px;
        border-radius: 2px;
        svg path {
          fill: #727272;
        }
        &:hover {
          background-color: #d9d9d90d;
          svg path {
            fill: #ffffff;
          }
        }

        &.active {
          background-color: #d9d9d933;
          color: #ffffff;
        }
      }

      .icon {
        font-size: 16px;
        cursor: pointer;
        &:before {
          color: #727272 !important;
        }
        &:hover {
          background-color: #d9d9d90d;
          &:before {
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .app-tags,
  .app-stats {
    margin-left: 20px;
  }

  .app-tags {
    display: flex;
    flex-wrap: nowrap;

    .app-tag {
      margin-right: 0.5em;
      margin-bottom: 0.2em;
      display: inline-block;
      background: rgba(45, 45, 45, 0.4);
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #a6a6a6;
      padding: 2px 4px;
      border-radius: 3px;
      flex: 0 0 auto;
    }
  }

  .app-stats {
    display: flex;
    gap: 12px;

    .stat {
      display: flex;
      align-items: flex-end;
      gap: 4px;
      .number {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;

        color: #d4d4d4;
      }

      .label {
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 12px;
        text-transform: uppercase;
        color: #ababab;
      }
    }
  }
}

.app-env-settings-modal {
  &,
  .header,
  .content,
  .actions {
    background-color: #232323 !important;
  }

  padding: 24px;
  .settings-container {
    display: flex;
    gap: 12px;
    flex-direction: column;

    .caution {
      font-style: normal;
      font-weight: 400;
      font-size: 7px;
      line-height: 13px;
      margin-top: -12px;
    }
    .settings-row {
      display: flex;
      align-items: center;

      .label {
        min-width: 250px;
      }

      .value {
        flex-grow: 1;
        width: 75%;
        display: flex;
        align-items: center;
        gap: 10px;
        input {
          flex-grow: 1;
        }
      }

      .checkbox label::before {
        background-color: black;
      }
    }
  }
}
