@import 'assets/styles/size.scss';
@import 'assets/styles/color.scss';

.profile-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3 * $spacing;
  overflow-y: overlay;
  height: 100%;
  margin-right: -$spacing; // this is to fit overflow to the right.
  background-color: #232323;

  .api-row {
    margin-top: 20px;
    .subtitle {
      display: flex;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #d4d4d4;
    }
    .highlight-box {
      display: flex;
      align-items: center;
      background-color: #151515;
      border-radius: 3px;
      padding: 4px 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 4px;

      .left-item {
        padding: 8px 0;
        float: left;
      }

      .clickable {
        cursor: pointer;
      }

      .highlight-box-content {
        flex-grow: 1;
        padding: 8px;
      }

      .highlight-box-title {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #ffffff;
      }

      &.disabled {
        background: rgba(21, 21, 21, 0.4);
        color: rgba(115, 115, 115, 0.76);
        .highlight-box-title {
          color: #737373;
        }
      }
    }
  }

  .profile-page-title {
    color: #4a474d;
    font-size: 22px;
    padding-bottom: 4 * $spacing;
  }

  .profile-page-subtitle {
    display: flex;
    color: #4a474d;
    font-size: 16px;
  }

  .ui.grid.profile-page-subtitle-grid,
  .ui.grid.profile-page-delete-account-grid {
    width: 100%;
    margin: 0;

    & > .row {
      padding-top: 0;
    }

    & > .row > .column {
      padding: 0;
    }

    .page-title {
      color: #8a8a8a;
      font-size: 24px;
    }

    .top-space {
      margin-top: 32px;
    }

    .subtitle {
      font-size: 14px;
    }

    .content {
      color: rgba(212, 212, 212, 0.76);
    }

    .docs-link {
      color: #495fe4;
      font-family: 'Inter UI';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      display: flex;

      .docs-link-wrapper {
        margin-left: auto;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      span {
        margin-right: 8px;
      }
    }

    .reset-password-text {
      color: $link-color;
      cursor: pointer;
      font-size: 11px;
    }

    .message {
      width: 100%;
    }

    .ui.divider {
      margin: 2 * $spacing 0;
    }
  }

  .profile-page-form {
    text-align: left;
    width: 50%;
    max-width: 800px;
    min-width: 500px;

    .fields {
      margin: 0 0 2 * $spacing 0;
    }

    .ui.divider {
      margin: 2 * $spacing 0;
    }

    .field > label {
      font-weight: 500;
      color: #827c88;
    }

    .error.field > label {
      font-weight: 500;
      color: red;
    }

    .ui.input {
      input {
        background-color: #2d2d2d;
        color: #737373;
        border: 1px solid #5f5f5f;

        &:focus {
          border: 1px solid #2a84f1;
          color: #a6a6a6;
        }
      }
    }

    .ui.message {
      margin-left: 6px;
    }

    .email-name-form-group {
      flex-direction: column;
      max-width: 240px;

      .field {
        margin-bottom: 6px;
      }
    }
  }

  .accept-button {
    font-size: 16px;
    font-weight: normal;
    color: $master-white;
    background-color: #5a2adc;
    margin-right: $spacing;

    &:hover {
      color: $master-white;
      background-color: darken(#5a2adc, 10%);
    }
  }

  .reject-button {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #838383 !important;
    background: none !important;

    &:hover {
      background-color: lighten(#838383, 40%) !important;
    }
  }

  .delete-button {
    font-size: 16px;
    font-weight: normal;
    color: #2c2a2e;
    background: #f8f8f9;

    &:hover {
      background-color: darken(#f8f8f9, 10%);
    }
  }
}

.delete-reasons-form-wrapper {
  margin: 20px 0;
  color: #172b4d;
  font-family: 'Inter UI';
  font-size: 12px;
  line-height: 16px;
}

.ui.form {
  .fields {
    .field {
      padding-left: 0;

      &.disabled {
        label {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

.message-box-row {
  margin-top: 32px;

  .message-box-wrapper {
    border: 1px solid #2a84f1;
    padding: 8px 14px !important;
    background-color: #363636;
    color: rgba(255, 255, 255, 0.7);

    i {
      margin-right: 14px !important;
      color: #d4d4d4;
    }

    .message-content {
      margin-top: 4px;
      padding-left: 26px;
    }
  }
}
