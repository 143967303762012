.collaborative-work-switcher {
  .collab-work-button {
    height: 16px;
    padding: 0 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #d9d9d9;
    background-color: #ffffff1f;
    cursor: pointer;
    &:hover {
      background: #ffffff33;
    }

    &.on {
      color: #ffffff;
      background-color: #1d76e1;
      &:hover {
        background: #2a84f1;
      }
    }
  }
}
