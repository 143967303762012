@import 'assets/styles/size.scss';

.top-header-bar {
  display: flex;
  // height: $top-header-bar-height;
  padding: $spacing;
  // margin-right: 60px; // 60px is the SUIR sidebar's 'very thin' size.
  border-bottom: 2px solid #e7e1f1;
}

.detail-layout-content {
  flex: 1; // not necessary, but we want this class to take all the height space it can take.
  height: 100%; //If not set this calc function not work in css
  padding: $spacing;
  // margin-right: 60px; // 60px is the SUIR sidebar's 'very thin' size.
  overflow: auto;
}
