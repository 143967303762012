@import 'assets/styles/color.scss';
@import 'assets/styles/mixins.scss';
@import 'assets/styles/size.scss';

body {
  .loading-view {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $blackish-6;
    .loading-bg-image {
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      background-image: url(~assets/images/hp.png);
      background-size: 545px 512px;
      background-position: right bottom;
    }
    .loading-real {
      position: absolute;
      z-index: 40;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 20%;
      .thundra-logo {
        display: inline-block;
        margin-bottom: 10px;
        width: 98px;
        height: 67px;
        background-image: url(~assets/images/thundra-loading-logo.svg);
        background-size: 100%;
        background-position: right bottom;
      }
      .loading-message {
        color: $white-1;
        text-align: center;
        font-family: 'Monda', sans-serif;
        font-weight: 400;
      }
      .loading-lines {
        margin: 20px auto 0;
        width: 100%;
        height: 4px;
        text-align: center;
        .load-base {
          display: inline-block;
          width: 150px;
          height: 4px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          background-color: $white-5;
          .load-in {
            position: absolute;
            top: 0;
            left: 10px;
            width: 90px;
            height: 6px;
            border-radius: 6px;
            background-color: $white-2;
            -webkit-animation: line-loading 2s infinite;
            -moz-animation: line-loading 2s infinite;
            -o-animation: line-loading 2s infinite;
            animation: line-loading 2s infinite;
          }
        }
      }
    }
  }
}

// A N I M A T I O N S
// Animations
@-webkit-keyframes line-loading {
  0% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  50% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 0;
    left: -90px;
    width: 90px;
  }
  53% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  100% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
}

@-moz-keyframes line-loading {
  0% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  50% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 0;
    left: -90px;
    width: 90px;
  }
  53% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  100% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
}

@-o-keyframes line-loading {
  0% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  50% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 0;
    left: -90px;
    width: 90px;
  }
  53% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  100% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
}

@keyframes line-loading {
  0% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  50% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  52% {
    opacity: 0;
    left: -90px;
    width: 90px;
  }
  53% {
    left: -90px;
    width: 90px;
    opacity: 1;
  }
  100% {
    left: 400px;
    width: 90px;
    opacity: 1;
  }
}
