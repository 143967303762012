.CodeMirror {
  height: 100%;
}

.CodeMirror-gutter-elt {
  left: 1px !important;
  width: 30px !important;
}

.icon-gutter-tracepoint-waiting {
  display: inline-block;
  animation: loading-spinner 2s linear infinite;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.icon-gutter-logpoint-waiting {
  display: inline-block;
  animation: loading-spinner 2s linear infinite;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.icon-gutter-both:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  color: rgb(132, 123, 203);
  background-color: rgb(132, 123, 203);
  border: 1px solid #ffd233;
}

.icon-gutter-logpoint-disable:before {
  content: '\e90a';
  color: #ffd233;
}

.icon-gutter-logpoint-disconnected:before {
  content: '\e90b';
  color: #8c7a3b;
}
.icon-gutter-logpoint-enable:before {
  content: '\e90c';
  color: #ffd233;
}
.icon-gutter-logpoint-waiting:before {
  content: '\e90d';
  color: #ffd233;
}

#source-editor-context-menu-popup {
  padding: 0;
  .source-context-header {
    margin-bottom: 12px;
  }

  .custom-profile-menu-wrapper {
    position: static;
  }

  .menu-section-title:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 7px;
    margin-top: 1px;
  }

  .tracepoint.menu-section-title:after {
    background-color: rgb(132, 123, 203);
  }

  .logpoint.menu-section-title:after {
    background-color: #ffd233;
  }

  .caption {
    padding: 1px 2px;
    font-size: 8px;
    color: #d9d9d9;
  }

  .last {
    border-bottom: 2px solid #d9d9d929;
  }
}

.add-log-expression-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .button {
    padding: 6px 12px;
  }

  .cancel {
  }
  .second-line {
    color: #cccccc;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.014em;
    text-align: left;
  }
  .actions-row {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

#source-editor-log-expression-menu-popup {
  background-color: #151515;
  width: 440px;
  max-width: unset;
  padding: 24px;

  label {
    color: #d9d9d9 !important;
    &:hover,
    &:focus {
      color: #d9d9d9 !important;
    }
  }
}
