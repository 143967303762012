.apps-and-tracepoints-container {
  .apps-and-tracepoints-header {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #fff;
    opacity: 0.7;
  }

  .tab {
    padding: 4px 12px;
    cursor: pointer;
    &.active {
      background-color: #232323;
    }
  }
  .ui.accordion .content {
    padding: 8px 12px 8px 8px !important;
  }
  .ui.accordion .title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
    background: #2d2d2d;
    display: flex;
    align-items: center;

    .accordion-title {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding-right: 5px;
      .checkbox {
        margin-left: auto;
        margin-right: 5px;
      }
    }

    .icon[class^='icon-']:before {
      font-size: 16px;
      color: rgba(217, 217, 217, 0.5);
    }
  }

  .event-list-search-container {
    width: unset;
    .input {
      margin: 0;
    }
  }

  .selected-sa-section {
    margin-top: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #d4d4d4;

    .checkbox {
      margin-right: 10px;
    }

    .actions {
      margin-left: auto;

      .icon {
        font-size: 16px;
        &:before {
          color: #727272 !important;
        }
        &:hover {
          background-color: #d9d9d90d;
          &:before {
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .apps-and-tps-placeholder {
    padding: 24px 10%;

    .header {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      .icon {
        margin-bottom: 12px;
        color: rgba(212, 212, 212, 0.4);

        &:before {
          color: rgba(212, 212, 212, 0.4);
        }
      }
    }

    .content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      display: flex;
      align-items: center;
      text-align: center;

      color: #d4d4d4;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: rgba(212, 212, 212, 0.76);
    }
  }

  .ui.input.filterAppsInput {
    margin: 0 1em;
  }

  .tracepoint-status {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    background: rgb(108, 108, 108);
    border: 2px solid rgb(108, 108, 108);

    &.app-connected {
      border-color: rgb(132, 123, 203);
      background: rgb(132, 123, 203);
    }

    &.disabled {
      background: transparent;
    }
  }

  .logpoint-status {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    background: #8c7a3b;
    border: 2px solid #8c7a3b;

    &.app-connected {
      border-color: #ffd233;
      background: #ffd233;
    }

    &.disabled {
      background: transparent;
    }
  }

  .tracepoints-table {
    tbody tr {
      &.waiting {
        opacity: 0.5;
      }
    }

    tbody {
      tr:hover,
      tr.active-row {
        background: #2d2d2d;

        .tracepoint-action,
        .tracepoint-action-label {
          visibility: visible;

          &.more-settings {
            width: 16px;
            border-radius: 2px;
            svg path {
              fill: #727272;
            }
            &:hover {
              background-color: #d9d9d90d;
              svg path {
                fill: #ffffff;
              }
            }

            &.active {
              background-color: #d9d9d933;
              color: #ffffff;
            }
          }
        }
      }
    }

    th {
      border: none !important;
      padding-left: 10px !important;

      .source-header {
        display: flex;
        margin-left: 9px;
      }

      .app-header {
        display: inline-flex;
        margin-left: 38px;
      }
    }

    td {
      border: none !important;
      padding: 0.5em 0px !important;
      font-size: 11px;
      line-height: 16px;
      color: #d4d4d4;

      .tp-source-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .app-status-indicator {
          margin: 0px 0px 0px 3px;
        }

        .filename-lineno {
          padding-left: 4px;
        }
      }

      .tp-app-name-container {
        padding-left: 48px;

        .tp-app-name {
          display: inline;
          margin-right: 0.5em;
        }
      }

      &.tracepoint-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .event-count {
          margin-left: auto;
        }

        .icon-apps-and-tps-waiting-tp {
          display: inline-block;
          animation: loading-spinner 2s linear infinite;

          @keyframes loading-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .tracepoint-action,
        .tracepoint-action-label {
          visibility: hidden;
          vertical-align: center;
        }

        .tracepoint-action {
          font-size: 16px;
          line-height: 16px;

          &:before {
            color: rgb(114, 114, 114);
          }
        }

        .tracepoint-action:hover:before {
          color: rgb(217, 217, 217);
        }

        .tracepoint-action-label {
          background: rgb(114, 114, 114);
          color: #2f2f2f;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          padding-top: 2px;
          padding-bottom: 2px;

          &:hover {
            background: rgb(217, 217, 217);
          }
        }
      }
    }
  }

  .trace-point-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px 5px;

    .btn-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .trace-point-edit-form {
    padding: 1em;

    .three.fields {
      margin: 0 0 1em !important;
    }

    .field > label {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.7);
    }

    .field {
      .tags {
        height: 50px;
        .default.text {
          margin-top: 8px;
          margin-left: 8px;
          padding: 0;
        }
      }
    }

    .time-unit-dropdown {
      font-size: 11px;
      color: #a6a6a6;
      padding: 4px 12px;
      min-height: 26px;
    }
  }
}

.predefined-info-popup {
  padding: 0 12px !important;
  padding-bottom: 12px !important;
  width: 240px;

  .predefined-info {
    color: #ffffff;
    font-style: normal;

    .header {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
      margin-top: 12px;
    }
    .info {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
  }
}
