@import 'assets/styles/color.scss';
@import 'assets/styles/size.scss';

.ui.segment.pushable {
  border-radius: 0;
  border-width: 0;
  margin: 0;
  background-color: #404040;
  .side-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d9d9d9;
    width: $sidebar-width;
    background-color: $ide-border-color;
    -webkit-transition: width 0.4s; /* For Safari 3.1 to 6.0 */
    transition: width 0.4s;
    padding-top: 20px;
    overflow-x: hidden;
    border-right: 1px #323232 solid;
    gap: 20px;

    .item.menu-item {
      padding: 0;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #d9d9d9;

      &:hover {
        cursor: pointer;
      }
    }

    i.icon {
      margin: 0;
    }
  }
}

.pusher.sidebar-pusher {
  display: flex;
  flex-direction: column;

  height: 100%;
  // width: 100%;
  // width: calc(100% - 50px);
  width: calc(100% - #{$sidebar-width});
  margin-left: $sidebar-width - 60px; // 60px is the SUIR sidebar's 'very thin' size.
}

.ui.popup.visible.user-menu-popup {
  padding: 0;
  left: 10px !important;

  &:before {
    background-color: #e0e1e2;
  }
}

.user-menu-wrapper {
  padding: 0 0 8px 0;
  background-color: #e0e1e2;
}

.screen-size-message {
  display: none;
}

@media (max-width: 900px) {
  .screen-size-message {
    display: block;
    padding: $spacing;
    color: #9f3a38;
    background-color: #fff6f6;
  }
}

.toast-content {
  &.Toastify__toast {
    padding: 12px;
    text-align: left;
    border-radius: 4px;

    &.Toastify__toast--success {
      border: solid #67b73e 1px;
      background-color: #85c167;
      color: white;
    }

    &.Toastify__toast--warning {
      border: solid #d07002 1px;
      background-color: #e98715;
      color: white;
    }

    &.Toastify__toast--error {
      border: solid #e63c3c 1px;
      background-color: #f26c6c;
      color: white;
    }
  }

  // We remove the x char from the toast.
  .Toastify__close-button {
    display: none;
  }
  .Toastify__close-button.Toastify__close-button--success {
    color: white;
  }
  .Toastify__close-button.Toastify__close-button--error {
    color: white;
  }

  .Toastify__progress-bar {
    height: 2px;

    &.Toastify__progress-bar--success {
      background-color: #67b73e;
    }
    &.Toastify__progress-bar--error {
      background-color: #e63c3c;
    }
  }
}
