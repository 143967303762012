.ui.modal.invite-user-modal {
  padding: 24px;
  width: 600px;

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.014em;

    color: #d9d9d9;
    padding: 0 !important;
    padding-bottom: 24px !important;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.014em;
    padding-bottom: 24px !important;

    color: #d9d9d9;
  }

  .invite-users-action-row {
    justify-content: flex-end;
    margin-top: 24px;
  }
}
