.cy-panzoom {
  position: absolute;
  font-size: 8px;
  color: #e3e0e6;
  font-family: arial, helvetica, sans-serif;
  line-height: 1;
  color: #666;
  z-index: 20;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-zoom-button {
  cursor: pointer;
  padding: 3px;
  text-align: center;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 8px;
  left: 18px;
  background: #e3e0e6;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-zoom-button:active,
.cy-panzoom-slider-handle:active,
.cy-panzoom-slider-handle.active {
  background: #ddd;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-button {
  position: absolute;
  z-index: 1;
  height: 16px;
  width: 16px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-reset {
  top: 50px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-zoom-in {
  top: 67px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-zoom-out {
  top: 197px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-up {
  top: 0;
  left: 50%;
  margin-left: -4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #666;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-down {
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #666;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-left {
  top: 50%;
  left: 0;
  margin-top: -4px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid #666;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-right {
  color: #949396;
  top: 50%;
  right: 0;
  margin-top: -4px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #666;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-pan-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #000;
  border-radius: 8px;
  margin-left: -5px;
  margin-top: -5px;
  display: none;
  z-index: 999;
  opacity: 0.6;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-slider {
  position: absolute;
  top: 81px;
  left: 17px;
  height: 115px;
  width: 15px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-slider-background {
  position: absolute;
  top: 0;
  width: 3px;
  height: 115px;
  left: 5px;
  background: #e3e0e6;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-slider-handle {
  position: absolute;
  width: 16px;
  height: 8px;
  background: #4f94bd;
  border-radius: 4px;
  margin-left: -1px;
  z-index: 999;
  line-height: 8px;
  cursor: default;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;

  .icon {
    display: none;
  }
}

.cy-panzoom-slider-handle .icon {
  margin: 0 4px;
  line-height: 10px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-no-zoom-tick {
  position: absolute;
  background: #666;
  border: 1px solid #e3e0e6;
  border-radius: 2px;
  margin-left: -1px;
  width: 7px;
  height: 2px;
  left: 3px;
  z-index: 1;
  margin-top: 3px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-panner {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  background-color: #e3e0e6;
  border-radius: 40px;
  margin-left: -1px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-panner-handle {
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 999;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.cy-panzoom-zoom-only .cy-panzoom-slider,
.cy-panzoom-zoom-only .cy-panzoom-panner {
  display: none;
}

.cy-panzoom-zoom-only .cy-panzoom-reset {
  top: 20px;
}

.cy-panzoom-zoom-only .cy-panzoom-zoom-in {
  top: 45px;
}

.cy-panzoom-zoom-only .cy-panzoom-zoom-out {
  top: 70px;
}

figure {
  cursor: -webkit-grab;
  cursor: grab;
}
figure:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
