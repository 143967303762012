.github-file-tree-wrapper {
  width: 100%;
  height: calc(100% - 25px); // 25px is for sources header height

  .workspaceContainer {
    background: #404040;
    color: #d9d9d9;

    // how to stop focused blue border?
    .fill {
      outline: none !important;
    }
    // this is to stop focused blue border!!
    .monaco-tree {
      outline: none;
    }

    .monaco-tree-row {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    a.label-name {
      color: #d9d9d9;
    }

    .monaco-icon-label::before {
      // This is to fix source icon widths.
      // Somehow it behaves different with default width 16px.
      width: 22px;
    }
  }
}
