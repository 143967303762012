.billing-tab-main-grid {
  max-width: 1000px !important;

  .last-section-row {
    margin-bottom: 46px;
  }
  .plan-header-row {
    max-width: 800px;
  }
  .info-row {
    max-width: 800px !important;
    justify-content: space-between;
    gap: 32px;
    .row {
      padding: 0;
    }
    .active-plan-column {
      max-width: 512px !important;
      width: 512px !important;

      .active-plan-main-grid {
        margin: 0;
        background-color: #1b1c1d;
        border-radius: 3px;
        padding: 16px 24px;

        .plan-header {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ababab;
          margin-bottom: 4px;
        }
        .plan-value {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #d9d9d9;
        }

        .active-plan-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .plan-name-header {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #ababab;
          }
        }

        .plan-info-column {
          border-right: 1px solid #323232;
        }

        .billing-info-column {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    .active-payment-method-column {
      max-width: 256px !important;
      width: 256px !important;
      .active-payment-method-main-grid {
        margin: 0;
        padding: 16px 24px;
        height: 100%;
        background-color: #1b1c1d;
        border-radius: 3px;
      }
    }
  }

  .billing-user-selection {
    .billing-user-combo {
      max-width: 240px;
    }
    .invite-button {
      width: fit-content;
      margin-top: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3794ff;
      &:hover {
        color: #097bff;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.active-payment-header {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #cccccc;
}

.change-payment {
  margin-top: auto;
}
.card-info {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ababab;
  .card-info-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
