@import 'assets/styles/size.scss';
@import 'assets/styles/color.scss';

.landing-page {
  height: 100%;
  min-height: 640px;
}

.from-aws-signup-note {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eae6ff;
  height: 32px;
  color: #091e42;
  font-family: 'Avenir Next';
  font-size: 16px;
  font-weight: 500;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: 'Avenir Next';
  background-color: #404040;
  overflow: auto;
}

.thundra-logo {
  margin: 40px 64px;

  .image {
    width: 230px;
    height: 50px;
  }
}

.landing-page-right-container {
  flex: 10;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .action-rect {
    display: flex;
    width: 100%;
    height: 100%;
    height: 400px;
  }

  .certificates {
    display: flex;
    width: 100%;
    padding-top: 80px;
    margin-top: auto;
    height: 200px;
    justify-content: center;
    align-items: center;

    .image {
      &.aws {
        height: 110px;
        margin: 0 12px;
      }

      &.gdpr {
        margin: 0 12px;
        width: 80;
        height: 80;
        border-radius: 40px;
        background: white;
      }
    }
  }

  .tos-copyright {
    text-align: center;
    width: 100%;
    padding: 36px 0;
    color: #d4d4d4;
    font-size: 10px;
    font-weight: 500;

    a {
      color: unset;
    }

    .interpunct-span {
      padding: 0 12px;
    }
  }
}
