/*  According to Lib Website https://fkhadra.github.io/react-toastify/how-to-style/
    Override existing css classes
*/

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;

  /*sidekick config*/
  padding: 12px 16px 12px 12px !important;
  background: #363636 !important;
  border: 1px solid #323232 !important;
  border-radius: 0 !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.Toastify__toast-body:before {
  font-family: FontAwesome;
  content: '\f05a';
  display: inline-block;
  padding-right: 8px;
  vertical-align: middle;
  font-weight: 900;
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  /*background-color: rgba(255, 255, 255, 0.7);*/
  background-color: #1d76e1 !important;
  transform-origin: left;
}
