.change-plan-main-grid {
  margin: 0 !important;

  .change-plan-header {
    display: flex !important;
    align-items: center;
    gap: 16px;
    div {
      margin-bottom: 0;
    }
  }
  .plan-summary-row,
  .instance-row,
  .user-row {
    height: 92px;
    border-bottom: 1px solid #323232;
    align-content: center;
  }
  .user-row {
    height: 134px;
    padding-bottom: 42px !important;
    margin-bottom: 16px;
  }
  .payment-row {
    position: sticky;
    bottom: 0;
    justify-content: space-between !important;
    //background-color: gray;

    .cancel-subscription {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .header-column {
    width: 160px !important;
  }
  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 6px;
  }

  .header-caption {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #ffffff;
  }

  .with-icon {
    display: flex;
    i {
      width: 8px;
      height: 8px;
      padding: 2px;
      margin: 0 2px !important;
      border-radius: 50%;
      background-color: #d9d9d9;
      color: #363636;
    }
  }
  .price-caption {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #d9d9d9;
    background-color: #d9d9d926;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
  }

  .content-column {
    width: 680px !important;
    display: flex !important;
    justify-content: space-between;
    gap: 10px;
    .input-container {
      background: #151515;
      padding: 8px 24px 8px 24px;
      display: flex;
      width: 100%;
      height: 44px;
      border-radius: 24px;
      input {
        width: 100%;
      }
    }
    .increment {
      width: 96px;
      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;

        background-color: #151515;
        border: unset;
        color: #ffffff;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.01em;
      }
    }
  }

  .price-column {
    width: 160px !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
  }

  .active-payment-method-column .active-payment-method-container {
    margin-bottom: 36px;
    background-color: #1b1c1d;
    max-width: 320px !important;
    border: 1px solid #2d2d2d;
    border-radius: 4px;
    .active-payment-method-main-grid {
      height: 120px;
      .row {
        padding-bottom: 8px;
        padding-top: 0;
      }
      margin: 0;
      padding: 12px;
      background-color: #1b1c1d;
      border-radius: 3px;
    }
  }

  .price-calc-column {
    padding-top: 16px !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
    .calculator {
      margin-bottom: 24px;
      padding-top: 16px;
      padding-left: 16px;
      width: 240px;
      max-width: 240px;
      .header,
      .header-caption {
        color: #cccccc;
        margin-bottom: 6px;
      }
      height: 120px;
      .calculator-row {
        display: flex;
        justify-content: space-between;
      }
    }

    .action-buttons {
      display: flex;
      gap: 8px;
      .confirm {
        width: fit-content;
      }
    }
  }
}
