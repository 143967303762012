.trace-point-event-table {
  &.ui.table {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #d9d9d9;
    border: none;
    width: 99%;

    /* Inside Auto Layout */
    thead th {
      background-color: #404040;
      cursor: auto;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #d9d9d9;
      height: 16px;
      border-radius: 0px !important;
      border-bottom: 1px solid #323232;
      position: sticky !important;
      top: 0;
      z-index: 35;
    }

    tr td {
      cursor: pointer;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #d9d9d9;
      max-height: 16px;

      .predefined-actions {
        display: flex;
        gap: 10px;
      }
    }

    tr {
      &.even {
        background-color: #464646;
      }

      &.odd {
        background-color: #404040;
      }

      &.highlighted {
        background-color: #2d2d2d;
      }

      .tbl-icon {
        cursor: pointer;
        visibility: hidden;
      }

      .trace-id-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .trace-id {
          height: 18px;
          width: 100px;
          padding: 0;
          position: relative;
          display: inline-block;
          margin: 0 5px 0 5px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      &:hover {
        background-color: #2d2d2d;

        .tbl-icon {
          visibility: visible;
        }
      }

      .tbl-link {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        white-space: nowrap;
      }
    }
  }
}
