.source-explorer-wrapper {
  width: 100%;
  height: 100%;
  color: #d4d4d4;
}

.source-tree-wrapper {
  width: 100%;
  height: calc(100% - 170px);

  .sources-branch-commit-info {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;

    .selected-repo-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .selected-repo-actions {
      display: flex;
      padding-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.sources-margin {
  width: 100%;
  height: 20px;

  .border {
    height: 100%;
    margin: 0 10px;
    border-bottom: 1px solid #d4d4d44d;
  }
}

.source-cache-list-wrapper {
  height: 150px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .source-cache-list {
    margin-top: auto;
  }

  .repo-list-item {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  }

  .repo-list-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      margin-right: 6px;
    }

    &.selected > span {
      border-bottom: 1px solid #d4d4d44d;
    }
  }

  .repo-list-item-actions {
    display: flex;
    padding-left: 8px;

    i:hover {
      cursor: pointer;
    }
  }
}

.ui.modal.change-selected-source-repo-modal {
  width: 400px;
  padding: 16px;

  .disconnect-source-content {
    .disconnect-source-title {
      margin-bottom: 16px;

      i {
        margin-right: 10px;
      }
    }

    .disconnect-source-title span {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    .disconnect-source-text span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
