.flexlayout__layout {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
}

.flexlayout__splitter {
  background-color: #272822;
}

@media (hover: hover) {
  .flexlayout__splitter:hover {
    background-color: #333333;
  }
}

.flexlayout__splitter_border {
  z-index: 10;
}

.flexlayout__splitter_drag {
  z-index: 1000;
  border-radius: 5px;
  background-color: #404040;
}

.flexlayout__outline_rect {
  position: absolute;
  cursor: move;
  box-sizing: border-box;
  border: 2px solid #cfe8ff;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
}

.flexlayout__outline_rect_edge {
  cursor: move;
  border: 2px solid #b7d1b5;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
}

.flexlayout__edge_rect {
  position: absolute;
  z-index: 1000;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: gray;
}

.flexlayout__drag_rect {
  position: absolute;
  cursor: move;
  color: white;
  background-color: #121212;
  border: 2px solid #333333;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
  opacity: 0.9;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  word-wrap: break-word;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
}

.flexlayout__tabset {
  overflow: hidden;
  background-color: #121212;
  box-sizing: border-box;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
  background-color: #121212;
}

.flexlayout__tabset_header {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  padding: 3px 3px 3px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #262626;
  color: white;
  background-color: #121212;
  box-shadow: inset 0 0 3px 0 rgba(136, 136, 136, 0.54);
}

.flexlayout__tabset_header_content {
  flex-grow: 1;
}

.flexlayout__tabset_tabbar_outer {
  box-sizing: border-box;
  background-color: #121212;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  background-color: #121212;
}

.flexlayout__tabset_tabbar_outer_top {
  border-bottom: 1px solid #262626;
}

.flexlayout__tabset_tabbar_outer_bottom {
  border-top: 1px solid #262626;
}

.flexlayout__tabset_tabbar_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.flexlayout__tabset_tabbar_inner_tab_container {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
}

.flexlayout__tabset_tabbar_inner_tab_container_top {
  /*border-top: 2px solid transparent;*/
}

.flexlayout__tabset_tabbar_inner_tab_container_bottom {
  border-bottom: 2px solid transparent;
}

.flexlayout__tabset-selected {
  background-image: linear-gradient(#404040, #404040);
}

.flexlayout__tabset-maximized {
  background-image: linear-gradient(#404040, #404040);
}

.flexlayout__tab {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: white;
  background-color: #404040;
}

.flexlayout__tab_button {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
  margin: 0px;
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.flexlayout__tab_button--selected {
  background-color: #262626;
  color: white;
}

@media (hover: hover) {
  .flexlayout__tab_button:hover {
    background-color: #262626;
    color: white;
  }
}

.flexlayout__tab_button--unselected {
  color: gray;
}

/*.flexlayout__tab_button_top {*/
/*    box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);*/
/*    border-top-left-radius: 3px;*/
/*    border-top-right-radius: 3px;*/
/*}*/

/*.flexlayout__tab_button_bottom {*/
/*    box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);*/
/*    border-bottom-left-radius: 3px;*/
/*    border-bottom-right-radius: 3px;*/
/*}*/

.flexlayout__tab_button_leading {
  display: inline-block;
}

.flexlayout__tab_button_content {
  display: inline-block;
}

.flexlayout__tab_button_textbox {
  border: none;
  color: green;
  background-color: #262626;
}

.flexlayout__tab_button_textbox:focus {
  outline: none;
}

.flexlayout__tab_button_trailing {
  display: inline-block;
  margin-left: 8px;
  min-width: 8px;
  min-height: 8px;
}

@media (pointer: coarse) {
  .flexlayout__tab_button_trailing {
    min-width: 20px;
    min-height: 20px;
  }
}

@media (hover: hover) {
  .flexlayout__tab_button:hover .flexlayout__tab_button_trailing {
    background: transparent url('./images/close.png') no-repeat center;
  }
}

.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
  background: transparent url('./images/close.png') no-repeat center;
}

.flexlayout__tab_button_overflow {
  margin-left: 10px;
  padding-left: 12px;
  border: none;
  color: #d9d9d9;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: transparent url('./images/more2.png') no-repeat left;
}

.flexlayout__tab_toolbar {
  display: flex;
  align-items: center;
}

.flexlayout__tab_toolbar_button {
  min-width: 20px;
  min-height: 20px;
  border: none;
  outline: none;
}

.flexlayout__tab_toolbar_button-min {
  background: transparent url('./images/maximize.png') no-repeat center;
}

.flexlayout__tab_toolbar_button-max {
  background: transparent url('./images/restore.png') no-repeat center;
}

.flexlayout__tab_toolbar_button-float {
  background: transparent url('./images/popout.png') no-repeat center;
}

.flexlayout__tab_floating {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexlayout__tab_floating_inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexlayout__tab_floating_inner div {
  margin-bottom: 5px;
  text-align: center;
}

.flexlayout__tab_floating_inner div a {
  color: royalblue;
}

.flexlayout__border {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
  background-color: #1a1a1a;
}

.flexlayout__border_top {
  border-bottom: 1px solid #323232;
  align-items: center;
}

.flexlayout__border_bottom {
  border-top: 1px solid #323232;
  align-items: center;
}

.flexlayout__border_left {
  border-right: 1px solid #323232;
  align-content: center;
  flex-direction: column;
}

.flexlayout__border_right {
  border-left: 1px solid #323232;
  align-content: center;
  flex-direction: column;
}

.flexlayout__border_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
}

.flexlayout__border_inner_tab_container {
  white-space: nowrap;
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
  background-color: #404040;
}

.flexlayout__border_inner_tab_container_right {
  transform-origin: top left;
  transform: rotate(90deg);
}

.flexlayout__border_inner_tab_container_left {
  flex-direction: row-reverse;
  transform-origin: top right;
  transform: rotate(-90deg);
}

.flexlayout__border_button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 12px;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.flexlayout__border_button--selected {
  background-color: #262626;
  color: white;
}

@media (hover: hover) {
  .flexlayout__border_button:hover {
    background-color: #262626;
    color: white;
  }
}

.flexlayout__border_button--unselected {
  color: gray;
}

.flexlayout__border_button_leading {
  display: inline;
}

.flexlayout__border_button_content {
  display: inline-block;
}

.flexlayout__border_button_trailing {
  display: inline-block;
  margin-left: 8px;
  min-width: 8px;
  min-height: 8px;
}

@media (pointer: coarse) {
  .flexlayout__border_button_trailing {
    min-width: 20px;
    min-height: 20px;
  }
}

@media (hover: hover) {
  .flexlayout__border_button:hover .flexlayout__border_button_trailing {
    background: transparent url('./images/close.png') no-repeat center;
  }
}

.flexlayout__border_button--selected .flexlayout__border_button_trailing {
  background: transparent url('./images/close.png') no-repeat center;
}

.flexlayout__border_toolbar {
  display: flex;
  align-items: center;
}

.flexlayout__border_toolbar_left {
  flex-direction: column;
}

.flexlayout__border_toolbar_right {
  flex-direction: column;
}

.flexlayout__border_toolbar_button {
  min-width: 20px;
  min-height: 20px;
  border: none;
  outline: none;
}

.flexlayout__border_toolbar_button-float {
  background: transparent url('./images/popout.png') no-repeat center;
}

.flexlayout__border_toolbar_button_overflow {
  border: none;
  padding-left: 12px;
  color: gray;
  font-size: inherit;
  background: transparent url('./images/more2.png') no-repeat left;
}

.flexlayout__border_toolbar_button_overflow_top,
.flexlayout__border_toolbar_button_overflow_bottom {
  margin-left: 10px;
}

.flexlayout__border_toolbar_button_overflow_right,
.flexlayout__border_toolbar_button_overflow_left {
  padding-right: 0px;
  margin-top: 5px;
}

.flexlayout__popup_menu {
  /*font-size: medium;*/
  /*font-family: Roboto, Arial, sans-serif;*/
  color: #d9d9d9;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.flexlayout__popup_menu_item {
  padding: 2px 10px 2px 10px;
  white-space: nowrap;
}

@media (hover: hover) {
  .flexlayout__popup_menu_item:hover {
    background-color: #4d4d4d;
  }
}

.flexlayout__popup_menu_container {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #4183c4;
  padding: 4px;
  background: #272822;
  border-radius: 3px;
  position: absolute;
  z-index: 1000;
  max-height: 50%;
  min-width: 100px;
  overflow: auto;
}

.flexlayout__floating_window _body {
  height: 100%;
}

.flexlayout__floating_window_content {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.flexlayout__floating_window_tab {
  overflow: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: black;
  color: white;
}

.flexlayout__error_boundary_container {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
}

.flexlayout__error_boundary_content {
  display: flex;
  align-items: center;
}

.flexlayout__tabset_sizer {
  padding-top: 5px;
  padding-bottom: 3px;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
}

.flexlayout__tabset_header_sizer {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
}

.flexlayout__border_sizer {
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: medium;
  font-family: Roboto, Arial, sans-serif;
}

/*# sourceMappingURL=dark.css.map */
