.variables-container {
  .workspaceContainer {
    background-color: #404040;

    .label-name,
    .label-description {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px !important;
      line-height: 16px;
      color: #d9d9d9;
    }

    .monaco-tree {
      outline: none;
    }

    .has-children.expanded {
      .monaco-node-label {
        &:before {
          color: #d9d9d9;
          font-size: 9px;
          font-family: FontAwesome;
          content: '\f078';
          display: inline-block;
          padding-right: 8px;
          vertical-align: middle;
          font-weight: 500;
        }
      }
    }

    .has-children {
      .monaco-node-label {
        &:before {
          color: #d9d9d9;
          font-size: 9px;
          font-family: FontAwesome;
          content: '\f054';
          display: inline-block;
          padding-right: 8px;
          vertical-align: middle;
          font-weight: 500;
        }
      }
    }
  }
}
