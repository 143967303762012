.workspace-tab-main-grid {
  .package-info-row {
    padding-bottom: 34px;
  }
  .package-info-grid {
    margin: unset;
    border-radius: 5px;
    background-color: #1b1c1d;
    max-width: 80%;
    padding: 26px 18px;
  }

  .api-key-container {
    .api-key {
      padding: 2%;
      border-radius: 3px;
      background-color: #2d2d2d;
      display: flex;
      justify-content: space-between;
    }
  }
}
