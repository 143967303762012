:root {
  --search-input-form-height: 24px;
}
.search-files-container {
  height: calc(100% - var(--search-input-form-height));
  overflow-y: hidden;
  .sourcefiles-table {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - var(--search-input-form-height));

    .sourcefile-meta {
      padding-left: 6px;
      padding-top: 6px;
      padding-bottom: 6px;
      cursor: pointer;
      height: max-content;
      &:hover {
        background-color: #4d4d4d;
      }

      &:active {
        background-color: #2d2d2d;
      }

      .icon-filename-container {
        display: flex;
        .sourcefile-name {
          padding-left: 6px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .sourcefile-path {
        padding-left: 20px;
        color: rgba(217, 217, 217, 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .no-results-text {
      padding-left: 6px;
      padding-top: 5px;

      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;

      display: flex;
      align-items: center;
      text-align: center;

      color: rgba(217, 217, 217, 0.5);
    }
  }

  .search-title-and-form-container {
    z-index: 99;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #404040;
    padding-bottom: 5px;
    .sourcefiles-search-input {
      background: #2d2d2d;
      border: 1px solid #232323;
      box-sizing: border-box;
      border-radius: 3px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;

      display: flex;
      align-items: center;

      color: #737373;
      cursor: pointer;
      &:active,
      &:focus {
        color: #d9d9d9;
        outline-style: none;
        border: 1px solid #2a84f1;
      }
      width: 100%;
      height: var(--search-input-form-height);
    }
  }

  .sourcefiles-search-input-form {
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 26px;
    padding-top: 5px;
    border-top: #323232 solid 1px;
    display: flex;
    height: var(--search-input-form-height);
  }
  .icon-thundra-clear-button {
    cursor: pointer;
    padding-top: 9px;
    margin-left: -26px;
    color: rgba(217, 217, 217, 0.5);
    transform: scale(0.75);
  }
}

.search-sources-loading-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #32323286;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.7);

  .icon-thundra-loading {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
