.sidekick-accordion {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  .title {
    padding: 2px;
    background-color: #2e3032;
    color: rgba(255, 255, 255, 0.7);
    .icon {
      height: 12px;
      width: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.1s ease, opacity 0.1s ease;
      transform: rotate(-90deg);
    }
    &.open {
      .icon {
        transform: rotate(0deg);
      }
    }
  }

  .panel {
    display: none;
    flex-grow: 1;
    overflow: hidden;
  }

  .panel.open {
    display: block;
  }
}
