.autocomplete-input {
  position: relative;

  .recommendation-popup {
    background-color: #1b1c1d;
    padding: 3px;
    border: 1px solid blue;
    max-height: 100px;
    overflow-y: scroll;
    border-radius: 2px;
    position: absolute;
    top: 44px;
    width: 100%;
    z-index: 1000;

    .suggestion {
      padding: 2px;

      &.active {
        background-color: gray;
      }
    }
  }
}
