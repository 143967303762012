.frameworks-container {
  .menu {
    a {
      padding: 4px 8px !important;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 11px;
      line-height: 16px !important;

      letter-spacing: -0.01em;

      color: #ababab !important;
      &.active.item {
        color: #d9d9d9 !important;
        border-bottom-color: #1d73dd !important;
      }
    }
  }
  .frameworks {
    display: flex;
    gap: 16px;

    .framework {
      display: flex;
      flex-direction: column;
      padding: 4px 8px;
      justify-content: center;
      gap: 4px;
      border-radius: 4px;
      &:hover {
        background-color: #4d4d4d;
      }
      img {
        width: 24px;
        height: 24px;
      }
      .label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: -0.005em;

        color: #ffffff;
      }
    }
  }
}

.instructions-modal {
  padding: 24px;
  max-height: 60%;
  .header {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.014em;

    color: #d9d9d9 !important;
  }

  .content {
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    .subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      color: #d4d4d4;
    }
    .main {
      margin-bottom: 4px;
    }

    .steps {
      .step {
        margin-bottom: 16px;
        .title-container {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-bottom: 4px;

          .index {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #d4d4d4;
            color: #151515;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            text-align: center;
          }
        }

        .caption {
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: rgba(212, 212, 212, 0.76);
          margin-bottom: 4px;
        }

        .gif {
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .caption,
        .gif,
        .action {
          margin-left: 32px;
        }
      }
    }
  }
}
