.download-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3794ff;

  &:hover {
    color: #097bff;
    cursor: pointer;
    text-decoration: underline;
  }
}
