@import 'assets/styles/color.scss';

.error-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1e1e20;
}

.error-page-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15%;
}

.error-pic {
  margin-bottom: 25px;
}

.error-title span {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #d4d4d4;
}

.error-subtitle {
  margin-top: 6px;
}

.error-subtitle div {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.21px;
  color: #d4d4d4;
}

.error-go-back-button {
  margin-top: 16px;
  border-radius: 3px;
  background-color: #1d76e1;
  height: 32px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0 24px;

  &:hover {
    cursor: pointer;
  }
}
