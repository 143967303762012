.ui.modal.json-diff-modal {
  padding: 24px;
  width: 848px;

  .header {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .content {
    max-height: 700px;
    overflow-y: scroll;
  }
}
