@import 'assets/styles/size.scss';

.workspaces-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #232323;

  .workspaces-page-main-grid {
    margin-top: 24px;
    max-width: 1000px !important;
    width: 1000px !important;
    .header-row {
      justify-content: unset !important;
      text-align: left !important;

      .workspaces-page-header {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: #ebebeb;
        margin-bottom: 16px;
      }

      .header-caption {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
    }
    .actions-row {
      justify-content: flex-end !important;
    }
  }

  .column {
    padding: 0 !important;
  }

  .ui.table {
    thead th {
      width: 450px;
      padding-right: 0;
    }

    .action-cell {
      display: flex;
      align-items: center;
    }
  }
}
