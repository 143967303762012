@import 'assets/styles/size.scss';
@import 'assets/styles/color.scss';
@import 'assets/styles/font.scss';

.tracemap-settings-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 20px;

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 30px;

    .extra-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0px 3px;

      height: 36px;
      width: 138px;
      border-radius: 3px;
      background-color: #f4f5f7;

      .tag-btn-container {
        height: 24px;
        width: 57px;
        border-radius: 3px;
        margin: 0px 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #172b4d;
        @include thundra-font-interUI-fS13-w500;

        &:hover {
          background-color: #ebecf0;
        }

        &.enabled {
          background-color: #364763;
          color: #ffffff;

          &:hover {
            background-color: #505c6f;
            color: #ffffff;
          }
        }
      }
    }

    .export-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0px 4px;
      height: 36px;
      width: 145px;
      border-radius: 3px;
      background-color: #f4f5f7;

      .export-btn-container {
        height: 24px;
        width: 117px;
        color: #172b4d;
        border-radius: 3px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        @include thundra-font-interUI-fS13-w500;

        &:hover {
          background-color: #ebecf0;
        }
      }
    }
  }
}

.tracemap-and-details-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0px !important;
  justify-content: space-between;

  .tracemap-container {
    height: 100%;
    overflow: hidden;

    #tracemapContainer {
      text-align: left;
      width: 100%;
      height: 80%; // todo: why 80?
      display: block;
    }
  }

  .details-container {
    box-shadow: -2px 4px 10px 0 #e7e1f1;
    height: 100%;

    .details-container-in {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      .btn-topology-close-details {
        position: fixed;
        cursor: pointer;
        margin: 0px 4px;
        padding: 2px;
        background-color: white;
        width: 30px;
        height: 30px;
        font-size: 13px;
        border-radius: 0px;
        box-shadow: -5px 0px 5px -1px #e7e1f1;
      }
    }
  }

  .btn-drag {
    background-color: transparent !important;
    position: relative;
    cursor: ew-resize;
    margin: 0px 0px;
    padding: 0px;
    width: 30px;
    height: 100%;
  }

  .btn-drag-dragging {
    background-color: transparent !important;
    border-radius: 0px;
    border-right: 2px solid #71737c;
    position: relative;
    cursor: ew-resize;
    margin: 0px 0px;
    padding: 0px;
    width: 30px;
    height: 100%;
    z-index: 9999;
  }
}

.btnSelected {
  background-color: #21ba45 !important;
  color: white !important;
}

.btnNormal {
  background-color: #db2828 !important;
  color: white !important;
}

.edge-label-show-hide {
  display: inline-block;
}
