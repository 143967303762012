.trace-point-events-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .events-empty-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon-line {
        font-size: 40px;
        opacity: 0.6;
      }

      .first-line {
        color: #d4d4d4;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.6;
      }

      .second-line {
        color: rgba(212, 212, 212, 0.76);
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.6;
      }

      .third-line {
        color: #3794ff;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.6;
      }
    }
  }
}
